import React from "react";
import InnerPageHeader from "./PageTitleHeader";
import Footer from "./Footer";

const AddPaymentMethod = (props) => {
  return (
    <div className="h-full flex flex-col">
      <InnerPageHeader title="Add Payment Account" />
      <div className="pt-8 px-4 flex-grow overflow-y-scroll scrollbar-hide">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead>
            <tr className="bg-white dark:bg-gray-800">
              <th className="px-2 py-2 font-semibold text-black whitespace-nowrap dark:text-white">
                Select a Payment Type
              </th>
              <th className="px-2 py-2 font-medium text-gray-600 float-right whitespace-nowrap dark:text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                  />
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white dark:bg-gray-800">
              <td
                scope="row"
                className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">eCheck</span>
              </td>
              <td className="px-2 py-4">Silver</td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
              <th
                scope="row"
                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">Visa</span>
              </th>
              <td className="px-2 py-4">White</td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
              <td
                scope="row"
                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">Mastercard</span>
              </td>
              <td className="px-2 py-4">Black</td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
              <td
                scope="row"
                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">Pay by Cash</span>
              </td>
              <td className="px-2 py-4">Black</td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
              <td
                scope="row"
                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">International Bank Transfer</span>
              </td>
              <td className="px-2 py-4">Black</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Footer />
    </div>
  );
};

export default AddPaymentMethod;
