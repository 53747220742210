export default function Accordion(props) {
  return (
    <div className="border-t mb-1">
      <h2 id="accordion-color-heading-1">
        <button
          type="button"
          className="flex items-center  text-left justify-between w-full px-0 py-5 font-medium rtl:text-right text-gray-500 border-b-1 border-gray-200 dark:text-gray-400 gap-3"
          onClick={props.toggleAccordion}
        >
          <p className="text-black flex-col">
            {props.title}
            <p className="text-slate-500 pt-2">{props.subTitle}</p>
          </p>

          <span className="text-sm">
            {props.rightText}
            {props.isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-5 pl-[4px] float-right"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-5 pl-[4px] float-right"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            )}
          </span>
        </button>
      </h2>
      {props.isOpen && <div className="bg-white">{props.data}</div>}
    </div>
  );
}
