import React from "react";
import Header from "./PageTitleHeader";
import Dropdown from "./DropDown";
import Footer from "./Footer";
 
import {
  currentMonthCharges,
  upcomingCharges,
} from "../data/AccountHistoryData";
 
const AccountHistory = () => {
  return (
    <>
      <div className="flex flex-col h-full">
        <Header title="Account History" />
        <div className="flex flex-col justify-between h-full overflow-y-scroll scrollbar-hide">
          <div className="p-6 flex-grow">
            <p className="text-sm font-semibold pb-2">
              Current Due (Includes past due, deposits)
            </p>
            <h1 className="text-4xl font-semibold border-b-2 pb-4">
              $4,727.00
            </h1>
            <div className="pb-4">
              <div className="pt-4">
                <p className="text-sm font-semibold pb-2">Filter by</p>
                {/* Dropdown */}
                <Dropdown options={["Current Month", "Past Dues", "Others"]} />
              </div>
            </div>
            {currentMonthCharges?.map((item, i) => (
              <div className="py-2 text-sm border-b-2" key={i}>
                <h1 className="font-semibold pb-1">{item.date}</h1>
                <div className="flex justify-between font-normal text-slate-600">
                  <p className="w-2/3">{item.desc}</p>
                  <div className="flex flex-col text-right">
                    <div className="text-teal-600">{item.charge}</div>
                    <div>{item.totalCharge}</div>
                  </div>
                </div>
              </div>
            ))}
 
            <div>
              <h1 className="pt-8 border-b-2 pb-1 mb-2 font-semibold">
                Upcoming Charges
              </h1>
              {upcomingCharges?.map((item, i) => (
                <div className="py-2 text-sm border-b-2" key={i}>
                  <h1 className="font-semibold pb-1">{item.date}</h1>
                  <div className="flex justify-between font-normal text-slate-600">
                    <p className="w-2/3">{item.desc}</p>
                    <div className="flex flex-col text-right">
                      <div className="text-teal-600">{item.charge}</div>
                      <div>{item.totalCharge}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
 
            <div className="text-xs text-slate-500 text-center py-2 pt-4">
              <p className="py-4">
                NOTE: Any Payments generated will not be reflected in your
                account until the next business day!
              </p>
              <p>
                Upcoming Charges: Estimated future charges for next month
                billing.
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
 
export default AccountHistory;