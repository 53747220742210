import React, { useState } from "react";
import Header from "./PageTitleHeader";
import Accordion from "./Accordion";
import Button from "./Button";
import Footer from "./Footer";
import Modal from "./Modal";
import PaymentMethod from "./PaymentMethod";
import CalenderDatePicker from "./CalenderDatePicker";

function InnerSchedulePayment() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accordions, setAccordion] = useState([
    {
      key: 1,
      title: "Payment Method",
      rightText: "Select Method",
      data: <PaymentMethod />,
    },
    {
      key: 2,
      title: "Amount to Pay",
      rightText: "Select Amount",
      data: (
        <div className="pb-4">
          <ul>
            <li className="pb-2">
              <input type="radio" /> <span>Current Balance</span>
            </li>
            <li className="pb-2">
              <input type="radio" /> <span>Total Amount</span>
            </li>
            <li className="pb-2">
              <input type="radio" /> <span>Fixed Amount</span>
            </li>
            <li className="pb-2">
              <input type="radio" /> <span>Percentage of Current Balance</span>
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: 3,
      title: "Frequency",
      rightText: "Select Frequency",
      data: (
        <div className="pb-4">
          <ul>
            <li className="pb-2">
              <input type="radio" /> <span>OneTime</span>
            </li>
            <li className="pb-2">
              <input type="radio" /> <span>Monthly</span>
            </li>
            <li className="pb-2">
              <input type="radio" /> <span>Weekly</span>
            </li>
            <li className="pb-2">
              <input type="radio" /> <span>Twice a Month(1st and 15th)</span>
            </li>
            <li className="pb-2">
              <input type="radio" /> <span>Yearly</span>
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: 4,
      title: "Date of First Payment ",
      rightText: "05/01/2024",
      data: <CalenderDatePicker />,
    },
    {
      key: 5,
      title: "Occurance",
      rightText: "Select Occurance",
      data: (
        <div className="pb-4">
          <ul>
            <li className="pb-2">
              <input type="radio" /> <span>Unit I cancel</span>
            </li>
            <li>
              <input type="radio" /> <span>A Specific numbers of times</span>
            </li>
          </ul>
        </div>
      ),
    },
  ]);

  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  const handleLearnMore = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="h-full flex flex-col">
      <Header title="Schedule Payments" />
      <div className="flex flex-col justify-between h-full overflow-y-scroll scrollbar-hide">
        <div className="p-4 flex-grow">
          <p className=" text-xl font-bold">Set Up Scheduled Payment</p>
          <div className="pt-4">
            {accordions.map((accordion, index) => (
              <Accordion
                isOpen={accordion.isOpen}
                toggleAccordion={() => toggleAccordion(accordion.key)}
                key={accordion.key}
                title={accordion.title}
                rightText={accordion.rightText}
                data={accordion.data}
              />
            ))}

            <table className="w-full border-b">
              <tbody>
                <tr className="py-4 border-t">
                  <td className="font-medium rtl:text-right w-2/4 text-gray-500 py-5">
                    <span className="float-left pr-[4px] text-black">
                      Payment Nickname
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                  </td>
                  <td className="py-5 float-right">
                    <input
                      type="text"
                      id="first_name"
                      class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      placeholder="Enter Nickname Here"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Button
            buttonText="Proceed To Confirmation"
            style="bg-teal-600 text-white"
          />

          <div className="py-4">
            <div className="flex items-center justify-around font-semibold">
              Offered by Winter Creek
            </div>
            <div
              className="flex items-center justify-around text-teal-600 font-semibold cursor-pointer"
              onClick={handleLearnMore}
            >
              Learn More
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Dialog Heading"
        content="This is a modal with some dummy in Sometimes it just doesn't make sense. The man walking down the street in a banana suit. The llama standing in the middle of the road. The fairies dancing in front of the car window. The fact that all of this was actually happening and wasn't a dream.
        She looked at her little girl who was about to become a teen. She tried to think back to when the girl had been younger but failed to pinpoint the exact moment when she had become a little too big to pick up and carry. It hit her all at once. She was no longer a little girl and she stood there speechless with fear, sadness, and pride all running through her at the same time.
        The lone lamp post of the one-street town flickered, not quite dead but definitely on its way out. Suitcase by her side, she paid no heed to the light, the street or the town. A car was coming down the street and with her arm outstretched and thumb in the air, she had a plan.formation."
        closeButtonText="Close"
        fullViewButtonText="Full View"
      />
    </div>
  );
}

export default InnerSchedulePayment;
