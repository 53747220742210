import React, { useState, useEffect } from "react";
 
const Dropdown = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
 
  useEffect(() => {
    setSelectedOption(options?.[0]); // Set the default selected option
  }, []);
 
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
 
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
 
  return (
    <div className="relative text-left">
      <button
        onClick={toggleDropdown}
        className="inline-flex justify-between w-full px-4 py-2 text-md font-semibold text-gray-700  border border-gray-300 rounded-md shadow-sm"
      >
        {selectedOption}
        <svg
          className={`w-6 h-6p ml-2 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0L5.23 8.29a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={`absolute left-0 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg transition-opacity duration-300 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        {options?.map((option, i) => (
          <div
            key={i}
            onClick={() => handleOptionClick(option)}
            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};
 
export default Dropdown;