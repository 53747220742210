import React from "react";

const Modal = ({
  isOpen,
  onClose,
  title,
  content,
  closeButtonText,
  fullViewButtonText,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10 ${
          isOpen ? "block" : "hidden"
        }`}
        onClick={onClose}
      />
      <div className="fixed z-20 inset-0 flex items-center justify-center">
        <div
          className={`bg-white rounded-lg max-w-sm h-2/3 mx-4 ${
            isOpen ? "shadow-2xl" : ""
          }`}
        >
          <div className="h-full flex flex-col">
            <div className="flex justify-between p-3">
              <div className="text-xl font-bold">{title}</div>
              <button className="text-xl font-bold" onClick={onClose}>
                &times;
              </button>
            </div>
            <div className="px-3 flex-grow overflow-y-scroll scrollbar-hide">
              {content}
            </div>
            <div className="flex justify-between p-3 border-t-2 space-x-4">
              <button
                className="bg-white text-teal-500 px-12 py-2 rounded-md shadow hover:bg-teal-100 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50"
                onClick={onClose}
              >
                {closeButtonText}
              </button>
              <button className="bg-teal-500 text-white px-12 py-2 rounded-md shadow hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50">
                {fullViewButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
