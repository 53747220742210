import React from "react";
import { useNavigate } from "react-router";
import Header from "./PageTitleHeader";
import Footer from "./Footer";
import Modal from "./Modal";
import Accordion from "./Accordion";
import Button from "./Button";
import { useState } from "react";
 
function SchedulePayment() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accordions, setAccordion] = useState([
    {
      key: 1,
      title: "Conflicting payment Test",
      subTitle: (
        <p>
          Authorized on 4/20/2024 <br></br> Variable - Current Amount Due
        </p>
      ),
      rightText: "",
      data: (
        <div>
          <div className="mt-4">
            <div className="flex justify-between">
              <div>Pay To:</div>
              <div className="font-semibold ml-auto">Winter Creek</div>
            </div>
            <div className="flex justify-start mt-2">
              <div>From:</div>
              <div className="font-semibold ml-auto">AB1 Test - Apr 1 30</div>
            </div>
            <div className="flex justify-start mt-2">
              <div>Effective Dates:</div>
              <div className="font-semibold ml-auto">5/2/2024 - Move-Out</div>
            </div>
            <div className="flex justify-start mt-2">
              <div>Frequency:</div>
              <div className="font-semibold ml-auto">Monthly</div>
            </div>
            <div className="flex justify-start mt-2">
              <div>Account:</div>
              <div className="font-semibold ml-auto">Realpage tester</div>
            </div>
          </div>
          <div className="mt-4">
            <Button
              onClick={() => handleLearnMore()}
              buttonText="Cancel"
              style="bg-white text-teal-600 border"
            />
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      key: 2,
      title: "Conflicting Payment",
      subTitle: (
        <p>
          Authorized on 4/20/2024 <br></br> Variable - Current Amount Due
        </p>
      ),
      data: "Select payment",
    },
    {
      key: 3,
      title: "Monthly Rent",
      subTitle: (
        <p>
          Authorized on 4/20/2024 <br></br> Variable - Current Amount Due
        </p>
      ),
      data: "Monthly rent",
    },
  ]);
 
  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });
 
    setAccordion(updatedAccordions);
  };
 
  const handleLearnMore = () => {
    setIsModalOpen(true);
  };
 
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
 
  return (
    <div className="h-full flex flex-col">
      <Header title="Scheduled Payment" />
      <div className="flex flex-col justify-between overflow-y-scroll scrollbar-hide">
        <div className="p-4">
          <div className="text-xl font-bold mb-3">Scheduled Payment</div>
          <div className="mb-6">
            Both active and cancelled scheduled payments are listed below. To
            cancel a scheduled payment, click the cancel button in the scheduled
            payment detail.
          </div>
          <Button
            buttonText="Schedule a new payment"
            style="bg-teal-600 text-white"
            onClick={() => navigate("/scheduleNewPayment")}
          />
 
          <div className=" mt-6 text-xl font-bold">Active Payments</div>
 
          <div className="my-4">
            {accordions.map((accordion, index) => (
              <Accordion
                key={accordion.key}
                title={accordion.title}
                subTitle={accordion.subTitle}
                rightText={accordion.rightText}
                data={accordion.data}
                isOpen={accordion.isOpen}
                toggleAccordion={() => toggleAccordion(accordion.key)}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Are you sure yu want to cancel this Scheduled Payment?"
        content="Cancellation of a scheduled payment is only valid a minimum of 2 banking days in advance of the payment date. If less than 2 banking days the payment will process but no future payments will."
        closeButtonText="No, Keep Payment"
        fullViewButtonText="Yes, Delete"
      />
    </div>
  );
}
 
export default SchedulePayment;