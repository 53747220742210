import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BottomNavigation from "./components/BottomNavigation"
import LandingPage from "./components/LandingPage";
import PayRentOnline from "./components/PayRentOnline";
import AccountHistory from "./components/accountHistory";
import SavedPaymentAccounts from "./components/SavedPaymentAccounts";
import SchedulePayment from "./components/SchedulePayment";
import InnerSchedulePayment from "./components/innerSchedulePayment";
import AddPaymentMethod from "./components/AddPaymentMethod";
 
function App() {
  return (
    <div className="h-screen">
      <div className="overflow-hidden" style={{ height: "calc(100vh - 57px)" }}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/payRentOnline" element={<PayRentOnline />} />
            <Route path="/accountHistory" element={<AccountHistory />} />
            <Route path="/savedPayments" element={<SavedPaymentAccounts />} /> 
            <Route path="/schedulePayment" element={<SchedulePayment />} /> 
            <Route path="/scheduleNewPayment" element={<InnerSchedulePayment />} /> 
            <Route path="/addPaymentMethod" element={<AddPaymentMethod />} />
          </Routes>
        </Router>
      </div>
      <BottomNavigation />
    </div>
  );
}
 
export default App;