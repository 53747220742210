import React from "react";
import Button from "./Button";

const DeletePaymentModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-6">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg overflow-hidden">
        <div className="flex flex-col px-4">
          <div className="flex justify-between">
            <p className="font-bold pt-2">
              Are you sure you want to delete this Payment Account?
            </p>
            <button
              onClick={onClose}
              className="text-gray-500  hover:text-gray-700 text-4xl"
            >
              &times;
            </button>
          </div>
          <div className="text-sm py-4">
            <p className="pb-4">
              If you delete this account, the scheduled payments associated with
              it will not process.
            </p>
            <p>
              You will need to create new scheduled payments with a new saved
              account. Are you sure you want to delete this account?
            </p>
          </div>
        </div>
        <div className="p-3 border-t-2 flex">
          <Button
            buttonText="No, Keep Account"
            style="text-teal-600 border-2 h-12 w-full mx-1"
            onClick={onClose}
          />
          <Button
            buttonText="Yes, Delete"
            style="bg-teal-600 h-12 text-white w-full mx-1"
          />
        </div>
      </div>
    </div>
  );
};

export default DeletePaymentModal;
