import React from "react";
import back from "../../src/assets/left-arrow.png";

const NavigateBack = (props) => {
  const {onClick} = props;

  return (
    <button className="h-12 w-12 flex items-center rounded-full hover:bg-gray-300 border-gray-300 border-[1px]" onClick={onClick}>
      <img src={back} alt="" className="h-8 w-5 mx-auto" />
    </button>
  );
};

export default NavigateBack;
