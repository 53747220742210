import React from "react";
 
const Footer = () => {
  return (
    <div>
      <div className="h-12 flex bg-slate-100 justify-center items-center text-teal-600 font-semibold">
        Powered by Realpage
      </div>
      <p className="h-16 bg-slate-800 text-white text-sm  text-center py-2">
        Active Building, LLC.{" "}
        <span className="underline underline-offset-4">Terms</span> under which
        this service is provided to you. Read our{" "}
        <span className="underline underline-offset-4">Privacy Policy</span>
      </p>
    </div>
  );
};
 
export default Footer;