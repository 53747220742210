export const currentMonthCharges = [
    {
      date: "4/1/2024",
      desc: "Cable Television Charges",
      charge: "$50.00",
      totalCharge: "$50.00",
    },
    {
      date: "4/1/2024",
      desc: "Rent",
      charge: "$337.50",
      totalCharge: "$387.50",
    },
    {
      date: "4/25/2024",
      desc: "WelcomeHome card payment -- AB1 Test 4/26/2024",
      charge: "$2.00",
      totalCharge: "$389.50",
    },
    {
      date: "4/25/2024",
      desc: "WelcomeHome card payment -- AB1 Test 4/26/2024",
      charge: "($389.50)",
      totalCharge: "$0.00",
    },
    {
      date: "4/25/2024",
      desc: "Once 4/27/2024",
      charge: "($387.50)",
      totalCharge: "($387.50)",
    },
  ];
   
  export const upcomingCharges = [
    {
      date: "5/1/2024",
      desc: "Cable Television Charges",
      charge: "$50.00",
      totalCharge: "$50.00",
    },
    {
      date: "5/1/2024",
      desc: "Rent",
      charge: "$337.50",
      totalCharge: "$387.50",
    },
  ];