import React, {useState} from "react";
import { useNavigate } from "react-router";

const PaymentMethod = () => { 
  const navigate = useNavigate() 

  return (
    <div className="items-center grid">
      <div className="relative">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <tbody>
            <tr className="bg-white dark:bg-gray-800">
              <td
                scope="row"
                className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">Apple MacBook Pro 17</span>
              </td>
              <td className="px-2 py-4">Silver</td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
              <th
                scope="row"
                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">Apple MacBook Pro 17</span>
              </th>
              <td className="px-2 py-4">White</td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
              <td
                scope="row"
                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="pl-4">Apple MacBook Pro 17</span>
              </td>
              <td className="px-2 py-4">Black</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        className="text-teal-600 m-auto py-4 w-11/12 justify-center bg-white rounded-lg border border-gray-200 inline-flex items-center"
        onClick={() => navigate("/addPaymentMethod")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span className="pl-2">Add New Payment Account</span>
      </button>
    </div>
  );
};

export default PaymentMethod;
