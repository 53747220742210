import React, { useState } from "react";
import PageTitleHeader from "./PageTitleHeader";
import PaymentMethod from "./PaymentMethod";
import PaymentAmount from "./PaymentAmount";
import CalenderDatePicker from "./CalenderDatePicker";
import AddPaymentMethod from "./AddPaymentMethod";
import Accordion from "./Accordion";
import Button from "./Button";
import Modal from "./Modal";
import Footer from "./Footer";

const PayRentOnline = () => {
  const [isReward, setRewards] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accordions, setAccordion] = useState([
    {
      key: 1,
      title: "Payment Method",
      rightText: "Select Method",
      data: <PaymentMethod />,
      isOpen: false,
    },
    {
      key: 2,
      title: "Payment Amount",
      rightText: "$4,727.00",
      data: <PaymentAmount />,
      isOpen: false,
    },
    {
      key: 3,
      title: "Payment Date",
      rightText: "04/29/2024",
      data: <CalenderDatePicker />,
      isOpen: false,
    },
  ]);

  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  const handleLearnMore = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="h-full flex flex-col">
      <PageTitleHeader title={"Pay Rent Online"} />
      <div className="flex-grow overflow-y-scroll scrollbar-hide">
        <div className="border-2 m-4 p-4 bg-slate-200 rounded-lg">
          {isReward ? (
            <div onClick={() => setRewards(false)}>
              {/* <button className="float-right" onClick={() => setRewards(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button> */}
              <h2 className="font-medium text-xl text-black">You're In!</h2>
              <p className="py-2 text-black">
                You'll now earn points on your first rent payment additional
                benefits? Pay rent with any card and watch your Rewards grow!
              </p>
              <p className="text-slate-400 italic">
                Points are only eligible one one payment per month.
              </p>
            </div>
          ) : (
            <>
              <h2 className="font-medium text-xl text-black">
                Earn Points When You Pay Rant
              </h2>
              <p className="py-2 text-black">
                Earn points on first rent payment of at least $500 each month,
                and unlock even more benefits when paying with a card!
              </p>
              <p className="text-slate-400 italic">
                Points are only eligible one one payment per month.
              </p>
              <button
                className="text-teal-600 m-auto py-3 mt-4 w-full mx-auto justify-center bg-white rounded-lg border border-gray-200 inline-flex items-center"
                onClick={() => setRewards(true)}
              >
                <span className="pl-2 text-center">Reward Me!</span>
              </button>
            </>
          )}
        </div>
        <div className="my-4 p-4">
          <h1 className="font-medium text-2xl">Make a One-Time Payment</h1>
          <p className="py-2 font-medium">{`Current Due(Includes past due)`}</p>
          <h1 className="font-medium text-2xl">$4,727.00</h1>
        </div>
        <div className="mb-6 p-4">
          <>
            {accordions.map((accordion) => (
              <Accordion
                key={accordion.key}
                title={accordion.title}
                rightText={accordion.rightText}
                data={accordion.data}
                isOpen={accordion.isOpen}
                toggleAccordion={() => toggleAccordion(accordion.key)}
              />
            ))}
            <table className="w-full border-b">
              <tbody>
                <tr className="py-4 border-t">
                  <td className="font-medium rtl:text-right text-gray-500 py-5">
                    <span className="float-left pr-2 text-black">
                      Security Code
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                  </td>
                  <td className="py-5 float-right">
                    <input
                      type="text"
                      id="first_name"
                      class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      placeholder="CVV"
                    />
                  </td>
                </tr>

                <tr className="border-t">
                  <td className="font-medium text-gray-500 py-5">
                    <span className="float-left pr-2 text-black">
                      Points Pending
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                  </td>
                  <td className="py-5 pr-2 float-right flex justify-center items-center text-md font-semibold text-gray-800">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="yellow"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 float-left"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                      />
                    </svg>
                    <span>500</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        </div>
        <Button
          buttonText={"Review Payment"}
          style={"bg-teal-600 text-white mx-4"}
        />
        <div className="text-center py-7">
          <h3 className="font-medium">Offered by Winter Creek</h3>
          <a
            className="mt-1 block text-teal-700 font-medium"
            onClick={() => handleLearnMore()}
          >
            Learn More
          </a>
        </div>

        <Footer />
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Dialog Heading"
        content="This is a modal with some dummy in Sometimes it just doesn't make sense. The man walking down the street in a banana suit. The llama standing in the middle of the road. The fairies dancing in front of the car window. The fact that all of this was actually happening and wasn't a dream.
        She looked at her little girl who was about to become a teen. She tried to think back to when the girl had been younger but failed to pinpoint the exact moment when she had become a little too big to pick up and carry. It hit her all at once. She was no longer a little girl and she stood there speechless with fear, sadness, and pride all running through her at the same time.
        The lone lamp post of the one-street town flickered, not quite dead but definitely on its way out. Suitcase by her side, she paid no heed to the light, the street or the town. A car was coming down the street and with her arm outstretched and thumb in the air, she had a plan.formation."
        closeButtonText="Close"
        fullViewButtonText="Full View"
      />
    </div>
  );
};

export default PayRentOnline;
