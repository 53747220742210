import React from "react";
 
const Button = ({ buttonText, buttonLogo, onClick, style }) => {
  return (
    <div className={`rounded-lg h-12 ${style}`} onClick={onClick}>
      <div className="flex h-full items-center justify-center">
        {buttonLogo && <img src={buttonLogo} alt="" className="h-4 w-4 mx-2" />}
        <p className="text-sm font-semibold">{buttonText}</p>
      </div>
    </div>
  );
};
 
export default Button;