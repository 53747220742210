import React, { useState } from "react";
import Header from "./PageTitleHeader";
import Footer from "./Footer";
import Button from "./Button";
import DeletePaymentModal from "./DeletePaymentModal";
import visa from "../assets/visa-logo.webp";
import { useNavigate } from "react-router-dom";
 
const SavedPaymentAccounts = () => {
    const navigate = useNavigate()
  const [isDeletePaymentModalOpen, setIsDeletePaymentModalOpen] =
    useState(false);
 
  return (
    <div className="flex flex-col h-full">
      <Header title="Saved Payment Accounts" />
      <div className="flex flex-col justify-between h-full overflow-y-scroll scrollbar-hide">
        <div className="flex-grow p-6">
          <div className="pb-6">
            <Button
              buttonText="Add New Payment Account"
              style="bg-teal-600 h-12 text-white"
              onClick={() => navigate('/addPaymentMethod')}
            />
          </div>
          <div>
            <h1 className="text-lg font-semibold pb-2">Payment Accounts</h1>
          </div>
          <div className="flex items-center justify-between border-b-2 pb-2">
            <div className="flex">
              <img className="w-6 h-4 border-[1px] m-4" src={visa} alt="" />
              <div className="flex flex-col text-sm">
                <p className="font-semibold">Test</p>
                <p>Visa Ending in 1111</p>
              </div>
            </div>
            <div className="">
              <Button
                buttonText="Delete"
                style="text-teal-600 border-2 h-10 px-6"
                onClick={() => setIsDeletePaymentModalOpen(true)}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {isDeletePaymentModalOpen && (
        <DeletePaymentModal
          onClose={() => setIsDeletePaymentModalOpen(false)}
        />
      )}
    </div>
  );
};
 
export default SavedPaymentAccounts;