import { useNavigate } from "react-router";
import Button from "./Button";
import logo from "../assets/calender.png";
import right from "../assets/right-arrow.png";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <div className="p-4">
        <h1 className="text-2xl text-hero font-bold mb-4">Payments</h1>
        <h2 className="text-md font-semibold mb-1">
          Current Due (Includes past due)
        </h2>
        <h2 className="text-4xl font-semibold">$4,727.00</h2>

        <div className="m-3 mt-6">
          <Button
            buttonText="Make a One-Time Payment"
            style="bg-teal-600 text-white"
            onClick={() => navigate("/payRentOnline")}
          />
        </div>
      </div>

      <div className="flex-grow overflow-y-scroll scrollbar-">
        <div
          className="flex justify-start border border-b-2  p-4"
          onClick={() => navigate("/schedulePayment")}
        >
          <img className="w-12 mt-2 mb-2" src={logo} />
          <div className="mt-3 mb-2">
            <div className=" font-semibold text-lg">Scheduled Payments</div>
            <div className="text-sm">Add and manage Scheduled payments</div>
          </div>
          <img className="w-6 h-5 mt-6 ml-auto" src={right} />
        </div>

        <div
          className="flex justify-start border-b-2 p-4"
          onClick={() => navigate("/accountHistory")}
        >
          <div className="mt-3 mb-2 ml-3">
            <div className=" font-semibold text-lg">Account History</div>
            <div className="text-sm">See all past payments</div>
          </div>
          <img className="w-6 h-5 mt-6 ml-auto" src={right} />
        </div>

        <div
          className="flex justify-start border-b-2 p-4"
          onClick={() => navigate("/savedPayments")}
        >
          <div className="mt-3 mb-2 ml-3">
            <div className=" font-semibold text-lg">Saved Payment Accounts</div>
            <div className="text-sm">
              Add and manage saved payment preferences
            </div>
          </div>
          <img className="w-6 h-5 mt-6 ml-auto" src={right} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
