import React from "react";
import NavigateBack from "./NavigateBack";
import { useNavigate } from "react-router-dom";
 
const InnerPageHeader = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-center border-b-2 px-5 p-4">
        <NavigateBack onClick={() => navigate(-1)} />
        <div className="font-semibold text-center text-lg w-5/6">{title}</div>
      </div>
    </div>
  );
};
 
export default InnerPageHeader;